import React, { Component } from "react"
import { Form } from 'semantic-ui-react';

import "./GeorgeInput.css"

const isNullOrEmpty = (value) => {
    return value === null || value === undefined || value === "";
}

export default class GeorgeInput extends Component {
    constructor() {
        super();
        this.state = {
            blurred: false
        }
    }

    onBlur = () => {
        this.setState({ blurred: true });
        if (this.props.onBlur) {
            this.props.onBlur();
        }
    }

    render() {
        const { value } = this.props;
        // Only stripping the required prop because I don't want the "Please fill required field" popup to show on submit.
        const { onBlur, error, required, ...rest } = this.props;
        const { blurred } = this.state;
        
        let hasError = false;
        if(isNullOrEmpty(error)) {
            hasError = blurred && required && isNullOrEmpty(value);
        } else {
            hasError = error[0];
        }
        return (
            <Form.Input
                error={hasError}
                onBlur={this.onBlur}
                {...rest}
            />
        );
    };    
}
