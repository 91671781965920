import React, { Component } from 'react'
import { Modal, Button } from 'semantic-ui-react'

import "./TermsModal.css";


export default class ModalExampleControlled extends Component {
    constructor() {
        super();
        this.state = { modalOpen: false };
    }

    handleOpen = () => this.setState({ modalOpen: true })

    handleClose = () => this.setState({ modalOpen: false })

    render() {
        return (
            <label>
                {"I agree to the "}
                <Modal 
                    trigger={<a onClick={this.handleOpen}>Terms and Conditions</a>}
                    open={this.state.modalOpen}
                    onClose={this.handleClose}
                    >
                    <Modal.Header>Terms and Conditions</Modal.Header>
                    <Modal.Content>
                    <Modal.Description>
                        <p>Transport By George! will guarantee service at the confirmed booking time for the quoted fixed price under the following terms and conditions:</p>
                        <ol>
                            <li>All Bookings are subject to confirmation.</li>
                            <li>Cancellation fees: We will charge a cancellation fee of $15 at our absolute discretion if the customer cancels a booking less than 30 minutes before pickup time or does not show up at the pickup address.</li>
                            <li>We may decline future business from customers who repeatedly cancel less than 30 minutes before pickup or who repeatedly do not show up at pickup time.</li>
                            <li>Waiting times at non airport pickups: We will wait up to 5 minutes for no extra charge.</li>
                            <li>More than 5 minutes waiting time will be charged @ $5.00 for every 5 minutes after that.</li>
                            <li>Airport Pickups: We will monitor Arrival times and wait until the flight is cleared. Your driver will contact you by phone or text message and ask for further instructions, if any.</li>
                            <li>All Airport pickups will have a $35 minimum charge including parking fees.</li>
                            <li>All Bookings require a minimum notice of 30 minutes.</li>
                        </ol>
                    </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button primary onClick={this.handleClose} content="Close" />
                    </Modal.Actions>
                </Modal>
            </label>
        );
    }
}
