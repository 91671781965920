import React from 'react';

import InfoFooter from '../Common/InfoFooter';
import { Divider } from 'semantic-ui-react';

const home = () => (
  <div>
    <h1>HOME</h1>
    <Divider />
    <p>Transport BY GEORGE! is a company formed by a number of current and former taxi operators who believe Canberra deserves better transport.</p>
    <p>The shareholders of Transport BY GEORGE! have over one hundred years combined experience in providing personal transport in Canberra.</p>
    <p>Our fleet consists of new vehicles which are reliable and offer a high level of passenger comfort.</p>
    <p>Whilst luxury limousine services are available, Transport BY GEORGE! bridges the gap between limousines and taxis by delivering quality service at reasonable prices.</p>
    <p>So, when you need to get somewhere - whether to or from the airport, a meeting, an appointment, or a social occasion, book with Transport BY GEORGE!</p>
    <br />
    <p>
      <b>Phone: </b>
      <a href="tel:0413436743">0413 436 743</a>
    </p>
    <p>
      <b>Email: </b>
      <a href="mailto:enquiries@transportbygeorge.com.au">enquiries@transportbygeorge.com.au</a>
    </p>
    <p>
      <b>Business Hours: </b>
      {'24/7'}
    </p>
    <InfoFooter />
  </div>
);

export default home;
