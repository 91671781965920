import React from 'react';
import { Image, Divider } from 'semantic-ui-react';

const services = () => (
  <div>
    <h1>SERVICES</h1>
    <Divider />
    <div id="local" className="anchor" />
    <h3>Local Travel and Airport Transfer</h3>
    <Image src="/images/airplane.jpg" size="large" floated="left" />
    <p>
      Very comfortable rides guaranteed to the railway station, bus station and airport 
      transfers at any time of the day or night or for any special occasions. Punctuality guaranteed! 
      Our driver will be at your pick up point ten minutes prior to your departure, will help you with
      your luggage, and deliver you on time to your destination. We can also provide forward facing child
      car seats and rear facing baby seats free of charge for all trips for young children when required.
    </p>
    <div id="winery" className="anchor" />
    <h3>Winery Tours</h3>
    <Image src="/images/winery.jpg" size="large" floated="right" />
    <p>
      We can take you or a group on a wine tasting tour, where you can choose and taste many different varieties
      of cold climate wines of the Canberra region, ACT, Murrumbateman, NSW and other areas in the vicinity. 
      Purchasing some bottles for your personal collection, or just wanting to spend a nice day out in the countryside.
      We take you to the wineries of your choice, wait for you, look after your purchases and return you back to your 
      home or accommodation. Generally, a standard tour would take about four to six hours.
    </p>
    <div id="canberra" className="anchor" />
    <h3>Short Canberra Tours</h3>
    <Image src="/images/parliament.jpg" size="large" floated="left" />
    <p>
      If you are in Canberra for a very short period, we can organise a tour of the city, showing the most 
      interesting highlights of the city precinct in one or two hours. The trip can be organised for up to four 
      people in one of our sedan cars, in a bespoke route or as a prearranged itinerary. For example one itinerary 
      could be from Canberra Airport, Mt Ainslie Lookout, City area, Parliamentary precinct, Royal Australian Mint, and 
      returning to Canberra Airport. Another possibility could include pick-up from Canberra Airport, Mt Ainslie 
      Lookout, City area, Parliamentary precinct, Lake Walter Burley Griffin West, Governor General House Lookout, 
      Royal Australian Mint, Embassies’ district then returning to Canberra Airport. During the tour you can stop 
      anytime for photos at your own pace. The one-hour tours run for $60 while two hour tours for $95, so it is great value!
    </p>
    <div id="alpine" className="anchor" />
    <h3>Alpine Area transfers</h3>
    <Image src="/images/snow.jpg" size="large" floated="right"/>
    <p>We do transfers to New South Wales regional towns surrounding the ACT including Queanbeyan, Murrumbateman, Yass, Goulbourn, Cooma and even Sydney all year around. In winter we can take you to Jindabyne and the skiing resorts of Perisher or Thredbo.
    We provide car snow chains, roof boxes or soft roof racks to make the trip safe and comfortable for you during the snow season.
    We can also provide interesting information on the best cross-country spots to chase the best white powder snow in this beautiful region.
    </p>
  </div>
);

export default services;
