import React, { Component } from 'react';
import Datetime from "react-datetime"
import moment from 'moment';


export default class GeorgeDateTimePicker extends Component {
    constructor() {
        super();
        this.state = {
            blurred: false
        }
    }

    onBlur = () => {
        this.setState({ blurred: true });
        if (this.props.onBlur) {
            this.props.onBlur();
        }
    }

    render() {
        const { date, dateFormat, timeFormat } = this.props;
        const { blurred } = this.state;
        let className = "";
        if (blurred){
            const valid = moment(date).isValid();
            if(!valid) {
                className = "error field";
            }
        } 
        let dFormat = dateFormat;
        if(dateFormat) {
            dFormat = "DD/MM/YYYY";
        }
        let tFormat = timeFormat;
        if(timeFormat) {
            tFormat = "HH:mm";
        }
        // Let's use the static moment reference in the Datetime component
        var yesterday = Datetime.moment().subtract(1, "days");
        var valid = function( current ){
            return current.isAfter( yesterday );
        };
        return <Datetime
            isValidDate={ valid }
            inputProps={{readOnly: true}}
            className={className}
            onBlur={this.onBlur}
            dateFormat={dFormat}
            timeFormat={tFormat}
            closeOnSelect={tFormat===false}
            value={date}
            onChange={this.props.onChange} />;
    };    
}
