import React from 'react';

import './Logo.css';

const logo = (props) => (
    <div className="logo-text">
        <span className="logo-text_transport">Transport BY </span>
        <span className="logo-text_george">GEORGE</span>
        <span className="logo-text_exclamation">!</span>
    </div>
);

export default logo;
