import React, { Component } from "react"
import { Button, Form, Divider } from "semantic-ui-react"
import { load } from 'recaptcha-v3'

import { withRouter } from "react-router-dom";
import GeorgeInput from "../Common/GeorgeInput";
import GeorgeDateTimePicker from "../Common/GeorgeDateTimePicker";
import { tacPost } from "../Common/DomainUtils";
import { shallowClone } from "../Common/componentUtils";
import BookingViewModel from "../Models/BookingViewModel";
import TermsModal from "../TermsModal/TermsModal";

const recaptchaPublicKey = '6Lezhs4UAAAAAAvb-tIhbwt5zhc4DQZO2LgoBD5Q';

class BookForm extends Component {
    constructor() {
        super();
        const data = new BookingViewModel();
        this.state = { 
            data, 
            response: {},
            recaptchaLoading: true,
            recaptcha: undefined,
            sending: false
        }
    }

    componentDidMount() {
        load(recaptchaPublicKey)
            .then((recaptcha) => this.setState({ recaptcha, recaptchaLoading: false }));
    }

    inputChange = (key, e) => {
        const x = shallowClone(this.state.data);
        this.clearErrorResponse(key);
        x[key] = e.target.value;
        this.setState({ data: x })
    }

    clearErrorResponse = (key) => {
        const responseClone = shallowClone(this.state.response);
        delete responseClone[key];
        this.setState({ response: responseClone });
    }

    dateChange = (key, e) => {
        const x = shallowClone(this.state.data);
        this.clearErrorResponse(key);
        x[key] = e.toDate ? e.toDate() : e;
        this.setState({ data: x })
    }

    checkboxChange = (key) => {
        const x = shallowClone(this.state.data);
        this.clearErrorResponse(key);
        x[key] = !x[key];;
        this.setState({ data: x })
    }
  
    submitClick = () => {
        const { sending, data, recaptcha } = this.state;
        if (sending){
            return;
        }

        this.setState({ sending: true });
        // Needs to be utc string so its doesnt break on google cloud
        const serialisableData = { ...data, pickupTime: data.pickupTime.toUTCString() };
        recaptcha.execute('allpages')
            .then((recaptchaToken) => {
                return tacPost("/api/book", { data: serialisableData, recaptchaToken })
                    .then(() => {
                        // Redirect to success page.
                        this.props.history.push(
                            {
                                pathname: "/success",
                                state: data
                            }
                        ); 
                    })
                    .catch(e => {
                        console.log(e);
                        this.setState({ response: e })
                    })
                    .finally(() => this.setState({ sending: false }));
            })
            .catch(e => {
                alert("Failed to book");
                console.error("Recaptcha token request failed");
                this.setState({ sending: false });
            });
    }

    render() {
        const { data, response, sending, recaptchaLoading } = this.state;
        const { name, mobile, pickupAddress, pickupTime, destinationAddress, notes, agreed } = data;
        const { errorMessage, name: errorName, mobile: errorMobile, pickupAddress: errorPickupAddress, pickupTime: errorPickupTime, destinationAddress: errordestinationAddress, agreed: errorAgreed } = response;
        const submitDisabled = recaptchaLoading || !agreed;
        return(
            <div>
                <h1>BOOK</h1>
                <Divider />
                <Form className="george-form">
                    <GeorgeInput
                        required
                        error={errorName}
                        name="name"
                        placeholder='Full Name' 
                        label="Name"
                        value={name} 
                        onChange={e => this.inputChange("name", e)}
                        />
                    <GeorgeInput 
                    required
                    name="phone"
                    type="tel"
                    error={errorMobile}
                    placeholder='04xxxxxxxx' 
                    label="Mobile"
                    value={mobile} 
                    onChange={e => this.inputChange("mobile", e)}
                    />
                    <GeorgeInput 
                        required
                        name="origin-address"
                        error={errorPickupAddress}
                        placeholder='Pickup Address' 
                        label="Pickup Address"
                        value={pickupAddress} 
                        onChange={e => this.inputChange("pickupAddress", e)}
                        />
                    <Form.Input 
                        label="Pickup Date"
                        required 
                        error={errorPickupTime ? errorPickupTime[0] : false}
                        >
                        <GeorgeDateTimePicker 
                        date={pickupTime}
                        dateFormat
                        onChange={e => this.dateChange("pickupTime", e)}
                        timeFormat={false}
                        />
                    </Form.Input>
                    <Form.Input
                        label="Pickup Time"
                        required 
                        error={errorPickupTime ? errorPickupTime[0] : false}
                        >
                        <GeorgeDateTimePicker 
                        date={pickupTime} 
                        dateFormat={false}
                        onChange={e => this.dateChange("pickupTime", e)}
                        />
                    </Form.Input>
                    <GeorgeInput 
                        required
                        name="destination-address"
                        error={errordestinationAddress}
                        placeholder='Destination Address' 
                        label="Destination Address"
                        value={destinationAddress} 
                        onChange={e => this.inputChange("destinationAddress", e)}
                        />
                    <Form.TextArea
                        label="Notes"
                        placeholder='Flight number, special requests, etc.' 
                        value={notes} 
                        onChange={e => this.inputChange("notes", e)}
                        />
                    <Form.Field>
                        <div className="recaptcha-disclaimer">
                            <p>{"This site is protected by reCAPTCHA and the Google "}
                                <a href="https://policies.google.com/privacy">Privacy Policy</a>{" and "}
                                <a href="https://policies.google.com/terms">Terms of Service</a>{" apply."}
                            </p>
                        </div>
                    </Form.Field>
                    <Form.Checkbox
                        required 
                        error={errorAgreed}
                        onClick={e => this.checkboxChange("agreed")}
                        checked={agreed}
                        label={<TermsModal />} 
                    />
                    { errorMessage }
                    <Form.Field>
                        <Button 
                        primary 
                        loading={sending || recaptchaLoading}
                        disabled={submitDisabled}
                        onClick={this.submitClick}
                        content="Submit" />
                    </Form.Field>
                </Form>
            </div>
        );
    }
}

export default withRouter(BookForm);