import React from 'react';

import { NavLink as Link } from 'react-router-dom';

const NavMenu = (props) => (
  <ul>
    <li>
      <Link exact to="/" onClick={props.onLinkClicked}>HOME</Link>
    </li>
    <li>
      <Link to="/book/" onClick={props.onLinkClicked}>BOOK</Link>
    </li>
    <li>
      <Link to="/services/" onClick={props.onLinkClicked}>SERVICES</Link>
    </li>
    <li>
      <Link to="/about/" onClick={props.onLinkClicked}>ABOUT US</Link>
    </li>
  </ul>
);

export default NavMenu;
