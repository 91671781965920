import React from 'react';
import { Divider } from 'semantic-ui-react';

const about = () => (
  <div>
    <h1>ABOUT US</h1>
    <Divider />
    <p>Transport BY GEORGE! is a company formed by a number of current and former taxi operators who believe Canberra deserves better transport.</p>
    <p>The shareholders of Transport BY GEORGE! have over one hundred years combined experience in providing personal transport in Canberra.</p>
    <p>Our fleet consists of new vehicles which are reliable and offer a high level of passenger comfort.</p>
    <p>Whilst luxury limousine services are available, Transport BY GEORGE! bridges the gap between limousines and taxis by delivering quality service at reasonable prices.</p>
    <p>So, when you need to get somewhere - whether to or from the airport, a meeting, an appointment, or a social occasion, book with Transport BY GEORGE!</p>
  </div>
);

export default about;
