
import "react-datetime/css/react-datetime.css";
import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Container } from "semantic-ui-react";

import './App.css';
import BookForm from './Pages/BookForm';
import Home from './Pages/Home';
import About from './Pages/About';
import Services from './Pages/Services';
import Success from './Pages/Success';
import SideDrawer from './SideDrawer/SideDrawer';
import Toolbar from './Toolbar/Toolbar';
import Footer from "./Footer/Footer";
import ScrollToTop from "./Common/ScrollToTop";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sideDrawerOpen: false,
        };
    }

    drawerToggleClickHandler = () => {
        this.setState(prevState => {
            return { sideDrawerOpen: !prevState.sideDrawerOpen }
        })
    }

    backdropClickHandler = () => {
        this.setState({ sideDrawerOpen: false })
    }

    onLinkClicked = () => {
        this.setState({ sideDrawerOpen: false });
    }

    render() {
        const { sideDrawerOpen } = this.state;
        return (
            <Router>
                <ScrollToTop>
                    <div className="App" style={{ height: '100%' }}>
                        <Toolbar 
                            drawerClickHandler={this.drawerToggleClickHandler}
                            sideDrawerOpen={sideDrawerOpen} />
                        <SideDrawer 
                            show={sideDrawerOpen}
                            onLinkClicked={this.onLinkClicked} 
                        />
                        <main className="body-main" >
                            <Container text>
                                <Route path="/" exact component={Home} />
                                <Route path="/book/" component={BookForm} />
                                <Route path="/services" component={Services} />
                                <Route path="/about/" component={About} />
                                <Route path="/success" component={Success} />
                            </Container>
                        </main>

                        <Container text>
                            <Footer />
                        </Container>
                    </div>
                </ScrollToTop>
            </Router>
        );
    }
}

export default App;
