import React from 'react';

import './Hamburger.css';

const hamburger = (props) => {
  let className = "hamburger hamburger--collapse";
  if(props.isActive) {
    className += " is-active";
  }
  return (
    <button className={className} type="button" onClick={props.click}>
    <span className="hamburger-box">
      <span className="hamburger-inner"></span>
    </span>
  </button>
  );
  }

export default hamburger;
