export const tacFetch = (url, init) => {
    if (!init) {
        init = setupHeader("get");
    }
    return fetch(url, init)
        .then((response) => {
            return response.text()
                .then((text) => {
                    if (text === "") {
                        if (response.ok) {
                            return Promise.resolve();
                        } else {
                            return Promise.reject("Something went wrong");
                        }
                    }
                    const json = JSON.parse(text);
                    if (!response.ok) {
                        return Promise.reject(json);
                    }
                    return Promise.resolve(json);
                });
        });
};

export const tacPost = (url, postData) => {
    const init = setupHeader("post", postData);
    return tacFetch(url, init);
};

const setupHeader = (method, postData) => {
    const init = { method, credentials: "include" };
    if (!init.headers) {
        init.headers = new Headers();
    }
    if (postData) {
        init.headers.delete("Content-Type");
        init.headers.append("Content-Type", "application/json");
        init.body = JSON.stringify(postData);
    }
    init.headers.append("Pragma", "no-cache");
    return init;
};