import React from 'react';

import './Toolbar.css';
import NavMenu from '../Common/NavMenu';
import Logo from '../Logo/Logo';

import { Link } from 'react-router-dom';
import Hamburger from '../Hamburger/Hamburger';

const toolbar = (props) => (
  <header className="toolbar">
    <nav className="toolbar__navigation">
      {/* Mobile View */}
      <div className="toolbar__toggle-button">
        <Hamburger 
          isActive={props.sideDrawerOpen}
          click={props.drawerClickHandler}
        />
      </div>
      {/* Mobile & Desktop View */}
      <div className="toolbar__logo">
        <Link exact to="/" onClick={props.onLinkClicked}>
          <Logo />
        </Link>
      </div>
      {/* Desktop View */}
      <div className="toolbar_navigation-items">
        <NavMenu />
      </div>
    </nav>
  </header>
);

export default toolbar;
