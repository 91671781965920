

import React from 'react';
import { Button } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

const infoFooter = () => (
  <div className="field">
    <Button primary as={Link} to="/book">Book Now</Button>
  </div>
);

export default infoFooter;
