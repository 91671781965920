

import React from 'react';

import "./Footer.css";
import NavMenu from '../Common/NavMenu';

import { Divider } from 'semantic-ui-react';

const Footer = () => (
  <footer>
    <br />
    <div className="footer-wrapper">
      <div className="footer_navigation-items">
        <NavMenu />
      </div>
      <Divider />
      <div className="footer-details">GEORGES HIRE CARS PTY LTD trading as Transport By George</div>
      <div className="footer-details">ABN 63 160 659 941</div>
      <div className="footer-details">ACT Hire Car Operator HC112S</div>
    </div>
  </footer>
);

export default Footer;
