import * as moment from "moment";

export default class BookingViewModel {
    constructor() {
        // Try set to puckup time next 30 mins block in the future
        const now = moment();
        const minutes = now.get("minutes");
        now.set({ minute:0, second:0, millisecond:0 })
        if(minutes >= 30) {
            now.add(1, "hour");
        } else {
            now.add(30, "minutes");
        }
        this.pickupTime = now.toDate();
        
        this.name = "";
        this.mobile = "";
        this.pickupAddress = "";
        this.destinationAddress = "";
        this.notes = "";
        this.agreed = false;

        // this.name = "Mark Kelly";
        // this.mobile = "0431219285";
        // this.pickupAddress = "4 Madden";
        // this.destinationAddress = "1 Octy";
        // this.notes = "I have a bike";
        // this.agreed = false;
    }

    name;
    mobile;
    pickupAddress;
    pickupTime;
    destinationAddress;
    notes;
    agreed;
}
