import React from 'react';

const success = (props) =>{
    const { name, mobile, pickupAddress, pickupTime, destinationAddress, notes } = props.location.state;
    const locale = 'en-AU';
    const displayPickupTime = pickupTime.toLocaleDateString(locale) + " " + pickupTime.toLocaleTimeString(locale, { timeZone: 'Australia/Sydney' });
    return (
    <div>
      <p>Thank you for your booking request.</p>
      <br />
      <p><b>You have requested:</b></p>
      <p>Transport for: {name}</p>
      <p>To be picked up from: {pickupAddress}</p>
      <p>At: {displayPickupTime}</p>
      <p>Going to: {destinationAddress}</p>
      {notes && <p>With notes: {notes}</p>}
      <p>We have the phone number: {mobile}</p>
      <br />
      <p><b>IMPORTANT:</b> While we have received your booking request it is not yet confirmed.</p>
      <p>We will be in touch shortly to confirm your journey and price. Once a booking number has been issued, you may then rely on your booking.</p>
    </div>
  );
}

export default success;