import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import './SideDrawer.css';

const sideDrawer = (props) => {
  let drawerClasses = 'side-drawer';
  if (props.show) {
    drawerClasses = 'side-drawer open';
  }
  return (
    <nav className={drawerClasses}>
      <ul>
        <li className="home-button">
          <Link exact to="/" onClick={props.onLinkClicked}>HOME</Link>
        </li>
        <li>
          <Link to="/book" onClick={props.onLinkClicked}>BOOK</Link>
        </li>
        <li>
          <Link to="/services" onClick={props.onLinkClicked}>SERVICES</Link>
          <ul>
            <li><HashLink to="/services#local" onClick={props.onLinkClicked}>- LOCAL TRAVEL</HashLink></li>
            <li><HashLink to="/services#winery" onClick={props.onLinkClicked}>- WINERY TOURS</HashLink></li>
            <li><HashLink to="/services#canberra" onClick={props.onLinkClicked}>- CANBERRA TOURS</HashLink></li>
            <li><HashLink smooth to="/services#alpine" onClick={props.onLinkClicked}>- ALPINE TRANSFERS</HashLink></li>
          </ul>
        </li>
        <li>
          <Link to="/about" onClick={props.onLinkClicked}>ABOUT US</Link>
        </li>
      </ul>
    </nav>
  );
};

export default sideDrawer;
